<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" @click="$router.go(-1)" title="Vissza">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading" title="Mentés">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Forgóvilla</h1>

    <v-card :loading="loading">
      <v-card-title>
        <v-icon left>mdi-turnstile-outline</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-autocomplete
              label="Kontroller"
              :error-messages="errors.controller_id"
              v-model="form.controller_id"
              :items="controllers"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Olvasó"
              :error-messages="errors.reader_id"
              v-model="form.reader_id"
              :items="readers"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
              :disabled="!form.controller_id"
              :persistent-hint="!form.controller_id"
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
            />
            <v-autocomplete
              label="Relé"
              :error-messages="errors.relay_id"
              v-model="form.relay_id"
              :items="relays"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
              :disabled="!form.controller_id"
              :persistent-hint="!form.controller_id"
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              :rows="3"
              auto-grow
            />

            <v-checkbox label="Kiegészítő eszköz" v-model="form.additional" />

            <v-text-field
              label="Megnevezés kiegészítés"
              :error-messages="errors.name_addition"
              v-model="form.name_addition"
              filled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület"
              :error-messages="errors.building_id"
              v-model="form.building_id"
              :items="buildings"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület szint"
              :error-messages="errors.building_level_id"
              v-model="form.building_level_id"
              :items="buildingLevels"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
              :disabled="!form.building_id"
              :persistent-hint="!form.building_id"
              :hint="form.building_id ? '' : 'Épület kötelező'"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-text-field
              label="X koordináta"
              :error-messages="errors.x_coord"
              v-model="form.x_coord"
              filled
              type="number"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-text-field
              label="Y koordináta"
              :error-messages="errors.y_coord"
              v-model="form.y_coord"
              filled
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      errors: {},
      loading: false,
    };
  },

  methods: {
    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('turnstiles/save/', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'TurnstileEditor') {
            this.$router.push({
              name: 'TurnstileEditor',
              params: { id: response.turnstile.element_id },
            });
          }
          this.fetchTurnstile();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    form: {
      async get() {
        this.loading = true;
        const response = await this.$http.get(`turnstiles/details/${this.$route.params.id || 0}`);
        this.loading = false;
        return response.turnstile;
      },
      default: {},
    },
    controllers: {
      async get() {
        const response = await this.$http.post('controllers/list');
        return response.controllers;
      },
      default: [],
      lazy: true,
    },
    relays: {
      async get() {
        const response = await this.$http.post('relays/list', {
          controller_id: this.form.controller_id,
        });
        return response.relays;
      },
      default: [],
      lazy: true,
    },
    readers: {
      async get() {
        const response = await this.$http.post('readers/list', {
          controller_id: this.form.controller_id,
        });
        return response.readers;
      },
      default: [],
      lazy: true,
    },
    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list');
        return response.buildings;
      },
      default: [],
      lazy: true,
    },
    buildingLevels: {
      async get() {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        return response.building_levels;
      },
      default: [],
      lazy: true,
    },
  },
};
</script>
